import { Rating } from 'react-simple-star-rating';

const StarsComponent = (props: any) => {
  const ratin = props?.data;

  const rate = ratin || 0;
  return (
    <div key={Math.random()} className="rating-stars d-flex align-items-center">
      <Rating
        initialValue={rate}
        readonly={true}
        size={18}
        allowFraction={true}
      />
      {props?.toggleText && <p className='sv-dark-grey-bold-para text-black mb-0 ms-2 mt-1'>{rate}</p>}
    </div>
  );
};

export default StarsComponent;
